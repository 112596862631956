import React, { useMemo } from 'react';
import lodashGet from 'lodash/get';
import lodashSome from 'lodash/some';
import PropTypes from 'prop-types';
import { useTheme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import formatPhoneNumber from 'services/format-phone-number';
import { useListingRealtor } from 'hooks/data/listing/useListingRealtor';
import { useListingMLS, useListingMLSByZipcode } from 'hooks/data/listing/useListingMLS';
import { COLOR_GREY_DARK } from '../../../constants';

const Separator = () => {
    const theme = useTheme();
    return (
        <span
            style={{
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0.5),
                color: '#A1A1A1',
            }}
        >
            |
        </span>
    );
};

const ListingAgentCard = React.memo(function ListingAgent({ listing }) {
    const { data: agent } = useListingRealtor(listing);
    const { data: listingMLS, isFetched: isFetchedListingMLS } = useListingMLS(listing);
    const { data: mlses, isFetched: isFetchedMLSes } = useListingMLSByZipcode(listing);

    const showMlsName = useMemo(() => {
        return lodashGet(listingMLS, '[0].add_mls_name_to_listing_agent_info', false);
    }, [listingMLS]);

    const hideAgent = useMemo(() => {
        return lodashGet(mlses, '[0].hide_listing_agent_from_detail_page', false);
    }, [mlses]);

    const attributionContact = useMemo(() => {
        function isValidEmail(email) {
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        }

        return (
            listing &&
            ((isValidEmail(listing.attribution_contact) && listing.attribution_contact) ||
                formatPhoneNumber(listing.attribution_contact) ||
                false)
        );
    }, [listing]);

    if (showMlsName) return null;

    return (
        <div
            style={{
                position: 'absolute',
                top: '56px',
                right: '16px',
                zIndex: '1000',
                backgroundColor: '#fff',
                minWidth: '253px',
                minHeight: '96px',
                padding: '16px',
            }}
        >
            <div>
                <span
                    style={{
                        fontSize: 10,
                        color: COLOR_GREY_DARK,
                        letterSpacing: '1.5px',
                        textTransform: 'uppercase',
                    }}
                >
                    listing agent:
                </span>

                <span>
                    {listing && agent && isFetchedMLSes ? (
                        <Typography variant="body1" style={{ fontSize: '12px' }}>
                            {!hideAgent && (
                                <>
                                    {agent?.first_name} {agent?.last_name}
                                    {(agent?.first_name || agent?.last_name) && agent?.company && (
                                        <Separator />
                                    )}
                                </>
                            )}
                            {agent?.company}
                            <br />
                            {attributionContact ? (
                                <>
                                    <Separator />
                                    <span>{attributionContact}</span>
                                </>
                            ) : (
                                (agent.phone || agent.email) && (
                                    <span>{agent.phone ? formatPhoneNumber(agent.phone) : agent.email}</span>
                                )
                            )}

                            {listing.mls_number && (
                                <>
                                    <Separator />
                                    <span style={{ whiteSpace: 'nowrap' }}>MLS# {listing.mls_number}</span>
                                </>
                            )}
                        </Typography>
                    ) : (
                        <Skeleton width="250px" />
                    )}
                </span>
            </div>
        </div>
    );
});

ListingAgentCard.propTypes = {
    listing: PropTypes.object.isRequired,
};

export default ListingAgentCard;
